import VgmSend from './VgmSend.vue'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
    components:{
      VgmSend,
    },
    data(){
        return {
            multipleSelection: [],
            DraftPop: false,
            importFormShow:true,
            addOnlineShow:false,
            tabShow:1,
            page:1,
            per_page:10,
            currentPage:1,
            count:0,
            carrierList:[
              {label:'兴亚船务',value:'710937324', carrierEn:'HEUNG-A'},
              {
                label:'柏辉船务',
                value: '742673263',
                carrierEn:'柏辉船务',
                children:[
                  {
                    label:'南星海运',
                    value: 'NSL',
                    carrierEn:'NAMSUNG'
                  }
                ]
              },
              {label:'马士基',value:'MAEU',carrierEn:'MAERSK'},
              {label:'MCCQ',value:'MCCQ',carrierEn:'MCC'},
              {label:'以星航运',value:'ZIM',carrierEn:'ZIM'},
              {label:'日本邮船',value:'NYKS',carrierEn:'NYK'},
              {label:'CMA',value:'CMA',carrierEn:'CMA'},
              {label:'韩进海运',value:'HJS',carrierEn:'HANJIN'},
              {label:'APL',value:'APLMBF',carrierEn:'APL'},
              {label:'宏海箱运',value:'60742304-2',carrierEn:'RCL'},
              {label:'现代商船',value:'x07266661',carrierEn:'HMM'},
              {label:'高丽海运',value:'kmtc',carrierEn:'KMTC'},
              {label:'亚海航运',value:'574161145a',carrierEn:'ASL'},
              {label:'华港船代',value:'13453884-2',carrierEn:'华港船代'},
              {label:'顺德船代',value:'560100333',carrierEn:'顺德船代'},
              {
                label:'航华船代',
                value: '150016700',
                carrierEn:'航华船代',
                children:[
                  {
                    label:'尼罗河海运',
                    value: 'NDS',
                    carrierEn:'NDS'
                  },
                  {
                    label:'汉堡南美',
                    value:'HSD',
                    carrierEn:'HBS'
                  },
                  {
                    label:'天津海运',
                    value:'TJM',
                    carrierEn:'TMSC'
                  },
                ]
              },
              {
                label:'上海外代',
                value:'132208081',
                carrierEn:'上海外代',
                children:[
                  {
                    label:'京汉船务',
                    value: 'COH',
                    carrierEn:'CO-HEUNG'
                  },
                  {
                    label:'正利航业',
                    value:'CNC',
                    carrierEn:'CNC'
                  },
                  // {
                  //   label:'高丽海运',
                  //   value:'KMT'
                  // },
                  {
                    label:'萨姆达拉',
                    value:'SSL',
                    carrierEn:'SSL'
                  },
                  {
                    label:'德翔海运',
                    value:'TSL',
                    carrierEn:'TSL'
                  },
                  {
                    label:'宝华海运',
                    value:'CST',
                    carrierEn:'COASTAL'
                  },
                  {
                    label:'神原汽船',
                    value:'KKC',
                    carrierEn:'KKC'
                  },
                  {
                    label:'新东海运',
                    value:'NOS',
                    carrierEn:'NOS'
                  },
                  {
                    label:'中通海运',
                    value:'CL',
                    carrierEn:'CCL'
                  },
                  {
                    label:'印度国航',
                    value:'SCI',
                    carrierEn:'SCI'
                  },
                  {
                    label:'顺发航运',
                    value:'SOF',
                    carrierEn:'SOFA'
                  },
                  {
                    label:'广州中航',
                    value:'718160724',
                    carrierEn:'广州中航'
                  },
                  {
                    label:'厦门远洋',
                    value:'155050328',
                    carrierEn:'厦门远洋'
                  },
                  {
                    label:'联合航运',
                    value:'UNL',
                    carrierEn:'UNL'
                  },
                  {
                    label:'非洲联合航运',
                    value:'UAF',
                    carrierEn:'UAF'
                  },
                  {
                    label:'联合航运有限公司（越南）',
                    value:'GMD',
                    carrierEn:'GMD'
                  },
                ]
              },
              {
                label:'联合船代',
                value:'133747781',
                carrierEn:'联合船代',
                children:[
                  {
                    label:'台航',
                    value:'TNC',
                    carrierEn:'TNC'
                  },
                  {
                    label:'博亚国际海运有限公司',
                    value:'BAL',
                    carrierEn:'BAL'
                  },
                  {
                    label:'上海中谷物流公司',
                    value:'757570881',
                    carrierEn:'上海中谷物流公司'
                  },
                ]
              },
              {label:'KLINE(VGM功能建设中)',value:'607304430',carrierEn:'KLINE'},
              {
                label:'鹏华船务',
                value:'717851400a',
                carrierEn:'鹏华船务',
                children:[
                  {
                    label:'太谷',
                    value:'VGM-swire',
                    carrierEn:'太谷'
                  },
                ]
              },
              {label:'FESCO',value:'717856789a',carrierEn:'FESCO'},
              {label:'锦江航运',value:'703051285',carrierEn:'JJ'},
              {label:'玛丽亚娜',value:'X07269504a',carrierEn:'MARIANA'},
              {
                label:'中联船代(原中和船代)',
                value: 'MA1G5DJT4',
                carrierEn:'中联船代(原中和船代)',
                children:[
                  {
                    label:'运达航运',
                    value:'IAL',
                    carrierEn:'IAL'
                  },
                  {
                    label:'中联航运',
                    value:'CUL',
                    carrierEn:'CUL'
                  },
                  {
                    label:'日照（香港）',
                    value:'559908271',
                    carrierEn:'RPS'
                  },
                ]
              },
              {label:'海运船务',value:'VGM-hycw',carrierEn:'海运船务'},
              {label:'澳航海运',value:'717856762',carrierEn:'ANL'},
              {label:'浦东外代',value:'13221686-6',carrierEn:'浦东外代'},
              {label:'赫伯罗特',value:'HLC',carrierEn:'HPL'},
              {label:'萨非航运',value:'SAFM',carrierEn:'SAF'},
              {label:'振华船代',value:'750314826',carrierEn:'振华船代'},
              {label:'长锦船务',value:'710939047',carrierEn:'SINOKOR'},
              {label:'太平船务',value:'X07269504',carrierEn:'PIL'},
              {label:'中波船代',value:'60730985-6',carrierEn:'中波船代'},
              {label:'新海船代',value:'133718147',carrierEn:'新海船代'},
              {label:'俄集运',value:'RCC',carrierEn:'俄集运'},
              {label:'森罗商船株式会社',value:'SML',carrierEn:'SML'},
              {label:'海领船务',value:'SEALEAD',carrierEn:'海领船务'},
              {label:'璟荣船务',value:'JRCW',carrierEn:'璟荣船务'},
              {label:'中和船代',value:'784276506c',carrierEn:'中和船代'},
              {label:'迪福船代',value:'598182952',carrierEn:'迪福船代'},
            ],
            typeList:[
              {name:'20英尺干货箱GP_22G1',value:'20GP'},
              {name:'40英尺干货箱GP_42G1',value:'40GP'},
              {name:'40英尺干货高箱GH_45G1',value:'40HQ'},
              {name:'20英尺冷冻箱RF_22R1',value:'20RF'},
              {name:'40英尺冷冻箱RF_42R1',value:'40RF'},
              {name:'40英尺冷高箱RH_45R1',value:'40RH'},
              {name:'20英尺框架箱FR_22P1',value:'20FR'},
              {name:'40英尺框架箱FR_42P1',value:'40FR'},
              {name:'20英尺开顶箱OT_22U1',value:'20OT'},
              {name:'40英尺开顶箱OT_42U1',value:'40OT'},
              {name:'20英尺油罐箱TK_22T1',value:'20TK'},
              {name:'40英尺油罐箱TK_42T1',value:'40TK'},
              
              // {name:'20英尺干货高箱GH_25G1',value:'25G1'},
              // {name:'20英尺挂衣箱HT_22V1',value:'22V1'},
              // {name:'20英尺冷高箱RH_25R1',value:'25R1'},
              // {name:'22P0',value:'22P0'},
              // {name:'40英尺挂衣箱HT_42V1',value:'42V1'},
              // {name:'42P0',value:'42P0'},
              // {name:'45英尺干货箱GP_L2G1',value:'L2G1'},
              // {name:'45英尺干货高箱GH_L5G1',value:'L5G1'},
              // {name:'45英尺挂衣箱HT_L2V1',value:'L2V1'},
              // {name:'45英尺开顶箱OT_L2U1',value:'L2U1'},
              // {name:'45英尺冷冻箱RF_L2R1',value:'L2R1'},
              // {name:'45英尺冷高箱RH_L5R1',value:'L5R1'},
              // {name:'45英尺油罐箱TK_L2T1',value:'L2T1'},
              // {name:'45英尺框架箱FR_L2P1',value:'L2P1'},
              // {name:'40英尺高挂衣箱_45V1',value:'45V1'},
              // {name:'45尺超标箱GP_L5GP',value:'L5GP'},
              // {name:'53英尺干货高箱_P5G1',value:'P5G1'},
              // {name:'10英尺冷冻箱RF_12R1',value:'12R1'},
              // {name:'45R9',value:'45R9'},
              // {name:'20HT(CMA)_25K1',value:'25K1'},
              // {name:'40HH(CMA)_45G4',value:'45G4'},
              // {name:'40HT(CMA)_45T1',value:'45T1'},
              // {name:'20英尺冷冻代干箱RD_22R9',value:'22R9'},
              // {name:'20TG_20T9',value:'20T9'},
              // {name:'40TG_48T9',value:'48T9'},
              // {name:'40FH_45P1',value:'45P1'},
              // {name:'25英尺开顶箱_25U1',value:'25U1'},
              // {name:'45英尺油罐箱(ZIM)_TG40',value:'TG40'},
            ],
            matouList:[
              {name:'上海浦东国际集装箱码头有限公司(外一)',value:'13220793-5'},
              {name:'上海盛东国际集装箱码头有限公司(洋一)',value:'775786045'},
              {name:'上海国际港务（集团）股份有限公司振东集装箱码头分公司(外二)',value:'wgq2'},
              {name:'上海冠东国际集装箱码头有限公司(洋三)',value:'662445084'},
              {name:'上海明东集装箱码头有限公司(外五)',value:'wgq5'},
              {name:'上海沪东集装箱码头管理有限公司(外四)',value:'74212819-3'},
              {name:'上海国际港务（集团）股份有限公司尚东集装箱码头分公司',value:'MA1FL3AGX'},
              // {name:'非上海码头',value:'OTHERPORT'},
              // {name:'上海国际港务（集团）股份有限公司罗泾分公司（散货）',value:'778078730b'},
              // {name:'上海国际港务（集团）股份有限公司宜东集装箱码头分公司',value:'778059556b'},
            ],
            page2:1,
            per_page2:10,
            currentPage2:1,
            count2:0,
            VGMData:{
              port:'SHANGHAI',//港口
              carrierArr:[],//接收方-承运人
              terminal:'',//码头
              blno:'',//提单号
              vessel:'',//船名
              voyage:'',//航次
              container_detais:[],
            },
            formLabelAlign: {
              ctn_no: '',//箱号
              seal_no: '',//封号
              ctn_type_size: '',//箱型
              vgm_gross_weight:'',// VGM重量
              vgm_method:'SM1',//称重方式
              company_name:'',//称重方
              cargo_tare_weight:'',//皮重
              vgm_date:'',//称重时间
              shipper_vgm_person_sig:'',//签名
            },
            addboxShow:false,
            TSLShow:false,
            KMTCShow:false,
            RCLShow:false,
            SINOKORShow:false,
            IALShow:false,
            // 表格上传船公司
            carrierBG:[],
            fileList: [],
            // 表格上传
            excelMsg:[],
            SentMsg:[],//已发送VGM
            // 条件筛选
            b_c_no:'',
            sent_b_c_no:'',
            // 集装箱按钮
            saveBtn:false,
            saveIdx:0,
            rules:{
              port: [
                { required: true, message: '请选择港口', trigger: 'change' }
              ],
              carrierArr: [
                { required: true, message: '请选择船公司', trigger: 'change' }
              ],
              terminal: [
                { required: true, message: '请选择码头', trigger: 'change' }
              ],
              blno: [
                { required: true, message: '请输入提单号', trigger: 'blur' }
              ],
              vessel: [
                { required: true, message: '请输入船名', trigger: 'blur' }
              ],
              voyage: [
                { required: true, message: '请输入航次', trigger: 'blur' }
              ],
              ctn_no: [
                { required: true, message: '请输入箱号', trigger: 'blur' },
                { pattern: /^\w{4}\d{7}$/g, message: '请输入正确的箱号(例:AAAA1234567)', trigger: 'blur'}
              ],
              ctn_type_size: [
                { required: true, message: '请选择箱型', trigger: 'change' }
              ],
              vgm_gross_weight: [
                { required: true, message: '请输入VGM重量', trigger: 'blur' }
              ],
              vgm_method: [
                { required: true, message: '请选择称重方式', trigger: 'change' }
              ],
              shipper_vgm_person_sig: [
                { required: true, message: '请输入签名', trigger: 'blur' }
              ],
              vgm_date: [
                { required: true, message: '请选择称重时间', trigger: 'change' }
              ],
          },
          carrier: '',
          autoComplete: false,
          autoCompleteMsg: {
            bl_no:''//EGLV142100738631
          },
          autoCompleteRules: {
              bl_no: [{required: true, message: '请填写提单号', trigger: 'blur'}]
          }
        }
    },
    filters:{
      // 港口转换
      portFilter(val){
        if(val == 'SHANGHAI'){
          return '上海'
        }
        if(val == 'SHENZHEN'){
          return '深圳'
        }
        if(val == 'GAUNGZHOU'){
          return '广州'
        }
        if(val == 'HONGKONG'){
          return '香港'
        }
        if(val == 'ZHUHAI'){
          return '珠海'
        }
        if(val == 'DALIAN'){
          return '大连'
        }
        if(val == 'NINGBO'){
          return '宁波'
        }
        if(val == 'QINGDAO'){
          return '青岛'
        }
        if(val == 'TIANJIN'){
          return '天津'
        }
        if(val == 'XIAMEN'){
          return '厦门'
        }
        if(val == 'FOSHAN'){
          return '佛山'
        }
        if(val == 'SHUNDE'){
          return '顺德'
        }
        if(val == 'ZHONGSHAN'){
          return '中山'
        }
        if(val == 'JIANGMEN'){
          return '江门'
        }
        if(val == 'ZHAOQING'){
          return '肇庆'
        }
        if(val == 'DONGGUAN'){
          return '东莞'
        }
        if(val == 'HAIPONG'){
          return 'HAI PHONG'
        }
      },
      // 码头转换
      matouFilter(val){
        if(val == '13220793-5'){
          return '上海浦东国际集装箱码头有限公司(外一)'
        }
        if(val == '775786045'){
          return '上海盛东国际集装箱码头有限公司(洋一)'
        }
        if(val == 'wgq2'){
          return '上海国际港务（集团）股份有限公司振东集装箱码头分公司(外二)'
        }
        if(val == '662445084'){
          return '上海冠东国际集装箱码头有限公司(洋三)'
        }
        if(val == 'wgq5'){
          return '上海明东集装箱码头有限公司(外五)'
        }
        if(val == '74212819-3'){
          return '上海沪东集装箱码头管理有限公司(外四)'
        }
        if(val == 'MA1FL3AGX'){
          return '上海国际港务（集团）股份有限公司尚东集装箱码头分公司'
        }
      },
    },
    created() {
      if(!localStorage.getItem('DDTtoken')){
        this.$alert('您还未登录，请登录', {
            confirmButtonText: '确定',
            callback:action => {
                if(action == 'confirm'){
                    this.$router.push('/login')
                }else {
                    this.$message({
                        type: 'info',
                        message: "取消",
                        center:true,
                        showClose: true,
                    });
                    this.$router.push('/')
                }
            }
          });
      } else {
        var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
        if(decoded.status == 0){
            this.$alert('您的账户正在审核中，审核通过即可使用VGM功能', {
                confirmButtonText: '确定',
                callback:action => {
                    if(action == 'confirm'){
                        this.$router.push('/')
                    }else {
                        this.$message({
                            type: 'info',
                            message: "取消",
                            center:true,
                            showClose: true,
                        });
                        this.$router.push('/')
                    }
                }
            });
        } else {
          // 查询草稿
          this.searchDraft()
        }
      }
    },
    methods:{
      changeTab(val){
        this.tabShow = val
        if(val == 1){
          this.importFormShow = true
          this.addOnlineShow = false
          this.searchDraft()
        }else if(val == 2){
          this.sentSearch()
        }
      },
      importForm(){
          this.DraftPop = true
          this.importFormShow = true
          this.addOnlineShow = false
      },
      // 关闭表格上传弹框
      closeDraftPop(){
          this.DraftPop = false
          this.carrierBG = []
          this.fileList = []
          this.searchDraft()
      },
      // 在线新增
      addOnline(){
          this.importFormShow = false
          this.addOnlineShow = true
      },
      // 自动填充数据
      autoCompleteSeach() { 
        this.$refs.autoCompleteMsg.validate(valid => {
          if (valid) { 
              axios.get(this.GLOBAL.BASE_URL1 + '/ContainerInfo/QueryOne', {
                  params: {
                      blNo: this.autoCompleteMsg.bl_no,
                      FILETYPE: 'ALL',
                      ie:'E'
                  }
              })
              .then(res => {
                if (res.data.status == '1' && res.data.billno.length > 0) {
                    this.commonCdReload(res.data)
                } else {
                  this.$message({
                    type: 'warning',
                    message: '数据查询失败',
                    showClose: true,
                    center: true
                  });
                }
                this.autoComplete = false
              })
              .catch(error => {
                  console.log(error);
              })
          }
        })
      },
      // 自动填充数据处理
      commonCdReload(data) { 
        this.VGMData.container_detais = []
        this.VGMData.port = data.billno[0].loading_port_info[1]
        this.VGMData.terminal = this.matouAutoFill(data.billno[0].terminal_code) 
        this.VGMData.blno= this.autoCompleteMsg.bl_no,
        this.VGMData.vessel= data.schedule[0].vessel_en,
        this.VGMData.voyage= data.schedule[0].export_voyage,
        data.billno.forEach(item => {
            var formLabelAlign = {
              ctn_no: item.carton_number,
              seal_no: item.seal_number,
              ctn_type_size: item.box_type,
              vgm_gross_weight: item.points_weight,
              cargo_tare_weight: item.box_gross_weight
            }
            this.VGMData.container_detais.push(formLabelAlign)
        });
      },
       // 自动填充码头转换
       matouAutoFill(val) {
        if(val == 'WGQ1'){
          return '13220793-5'
        }
        if(val == 'YS01'){
          return '775786045'
        }
        if(val == 'WGQ2'){
          return 'wgq2'
        }
        if(val == 'YS03'){
          return '662445084'
        }
        if(val == 'WGQ5'){
          return 'wgq5)'
        }
        if(val == 'WGQ4'){
          return '74212819-3'
        }
        if(val == 'YS04'){
          return 'MA1FL3AGX'
        }
       },
      //选择船公司
      changeCarrier(value) {
        this.carrierList.map(item => {
          if (item.value == value[0]) {
            if (value[1]) {
              item.children.map(items => {
                if (items.value == value[1]) { 
                 this.carrier = items.carrierEn
                }
              })
            } else {
             this.carrier = item.carrierEn
            }
          }
        })
        if(value && value[1] == 'TSL'){//德翔海运(TSL)
          this.TSLShow = true
        }else {
          this.TSLShow = false
        }
        if(value && (value[0] == 'kmtc' || value[1] == 'KMT')) {//高丽海运(KMTC)
          this.KMTCShow = true
        }else {
          this.KMTCShow = false
        }
        if(value && value[0] == '60742304-2'){//宏海箱运(RCL)
          this.RCLShow = true
        }else {
          this.RCLShow = false
        }
        if(value && value[0] == '710939047'){ //长锦船务（SINOKOR）
          this.SINOKORShow = true
        }else {
          this.SINOKORShow = false
        }
        if(value && value[1] == 'IAL'){//运达航运
          this.IALShow= true
        }else {
          this.IALShow= false
        }
      },
      // 添加集装箱
      addBox(){
        if(this.VGMData.carrierArr && this.VGMData.carrierArr.length != 0){
          this.addboxShow = true
          this.saveBtn = false
          this.formLabelAlign={
            ctn_no: '',//箱号
            seal_no: '',//封号
            ctn_type_size: '',//箱型
            vgm_gross_weight:'',// VGM重量
            vgm_method:'SM1',//称重方式
            company_name:'',//称重方
            cargo_tare_weight:'',//皮重
            vgm_date:'',//称重时间
            shipper_vgm_person_sig:'',//签名
          }
        }else {
          this.$message({
            type: 'warning',
            message: '请先选择船公司',
            showClose: true,
            center: true
          });
        }
      },
      // 关闭集装箱添加弹框
      closeAddBox(){
        this.addboxShow = false
        this.saveBtn = false
      },
      // 验证重量
      checkWeight(val){
        let num = '' + val
        num = num
            .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
            .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
            // eslint-disable-next-line no-useless-escape
            .replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3') // 只能输入三个小数
        if (num.indexOf('.') < 0 && num !== '') {
            // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
            num = parseFloat(num)
        }
        val = num
        this.formLabelAlign.vgm_gross_weight = val
      },
      checkTareWeight(val){
        let num = '' + val
        num = num
            .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
            .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
            // eslint-disable-next-line no-useless-escape
            .replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3') // 只能输入三个小数
        if (num.indexOf('.') < 0 && num !== '') {
            // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
            num = parseFloat(num)
        }
        val = num
        this.formLabelAlign.cargo_tare_weight = val
      },
      // 添加集装箱提交
      AddBoxSub(){
        this.$refs.formLabelAlign.validate(valid=>{
          if(valid){
            console.log('验证通过');
            this.formLabelAlign.vgm_gross_weight =this.formLabelAlign.vgm_gross_weight ? parseFloat(this.formLabelAlign.vgm_gross_weight):null
            this.formLabelAlign.cargo_tare_weight =this.formLabelAlign.cargo_tare_weight? parseFloat(this.formLabelAlign.cargo_tare_weight):null
            this.VGMData.container_detais.push(this.formLabelAlign)
            this.addboxShow = false
          }
        })
      },
      // VGM保存发送
      sendVGM(){
        this.$refs.VGMData.validate(valid=>{
          if(valid){
            if(this.VGMData.container_detais.length == 0){
              this.$message({
                type: 'warning',
                message: '请添加集装箱信息',
                showClose: true,
                center: true
              });
            } else {
              console.log(this.VGMData);
              axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
              axios.defaults.headers.common['Content-Type'] = 'application/json'
              axios.post(this.GLOBAL.BASE_URL1 + '/yitong_vgm/test',{
                data:[
                  {
                    id:this.VGMData.id||null,
                    port:this.VGMData.port,
                    carrier:this.carrier,
                    file_function:2,
                    reference_no:this.VGMData.blno,
                    blno:this.VGMData.blno,
                    receiver_code:this.VGMData.carrierArr[0],
                    carrier_code:this.VGMData.carrierArr[1] || this.VGMData.carrierArr[0],
                    vessel:this.VGMData.vessel,
                    voyage:this.VGMData.voyage,
                    terminal:this.VGMData.terminal,
                    container_detais:this.VGMData.container_detais}
                  ]
              })
              .then(res=>{
                if(res.data.status == 1){
                  this.$message({
                    type: 'success',
                    message: res.data.message,
                    showClose: true,
                    center: true
                  });
                  this.VGMData={
                    port:'SHANGHAI',//港口
                    carrierArr:[],//接收方-承运人
                    terminal:'',//码头
                    blno:'',//提单号
                    vessel:'',//船名
                    voyage:'',//航次
                    container_detais:[],
                  }
                }else {
                  this.$message({
                    type: 'error',
                    message: res.data.message,
                    showClose: true,
                    center: true
                  });
                }
              })
              .catch(error=>{
                console.log(error);
              })
            }
          }
        })
      },
      // 集装箱信息编辑
      auitBoxItem(index,val){
        this.addboxShow = true
        this.saveBtn = true
        this.formLabelAlign = val
        this.saveIdx = index
      },
      // 集装箱信息保存
      saveBoxSub(){
        this.$refs.formLabelAlign.validate(valid=>{
          if(valid){
            console.log('验证通过');
            this.formLabelAlign.vgm_gross_weight =this.formLabelAlign.vgm_gross_weight ? parseFloat(this.formLabelAlign.vgm_gross_weight):null
            this.formLabelAlign.cargo_tare_weight =this.formLabelAlign.cargo_tare_weight? parseFloat(this.formLabelAlign.cargo_tare_weight):null
            this.VGMData.container_detais.splice(this.saveIdx,1,this.formLabelAlign)
            this.addboxShow = false
            this.saveBtn = false
          }
        })
      },
      // 集装箱信息删除
      delBoxItem(index, rows){
        this.$confirm('删除后数据无法恢复, 确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          rows.splice(index, 1);
          this.$message({
            type: 'success',
            message: '删除成功!',
            showClose: true,
            center: true
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            showClose: true,
            center: true
          });
        });
      },
      // 关闭在线新增
      closeOnlineAdd(){
        this.importFormShow = true
        this.addOnlineShow = false
        this.VGMData={
          port:'SHANGHAI',//港口
          carrierArr:[],//接收方-承运人
          terminal:'',//码头
          blno:'',//提单号
          vessel:'',//船名
          voyage:'',//航次
          container_detais:[],
        }
        this.$refs.VGMData.resetFields();
        this.searchDraft()
      },
      // 表格模板下载
      downloadModel(){
        if(this.carrierBG.length == 0){
          this.$message({
            type: 'warning',
            message: '请选择船公司',
            showClose: true,
            center: true
          });
        }else {
          var fd = new FormData()
          fd.append('tag',this.carrierBG[1] || this.carrierBG[0])
          axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
          axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
          axios.post(this.GLOBAL.BASE_URL1 + '/VGM_other/downloads',fd)
          .then(res=>{
            window.open(res.data)
          })
          .catch(error=>{
            console.log(error);
          })
        }
      },
      // 表格上传
      OnSuccess (file,fileList) {
        this.fileList = fileList
        var fd = new FormData()
        fd.append('file',file.raw)
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
        axios.post( this.GLOBAL.BASE_URL1 +'/VGM_other/Excel',fd )
        .then(res => {
          console.log(res);
          if(res.data.status == 1){
            this.searchDraft()
            this.$message({
              type: 'success',
              message: res.data.message,
              showClose: true,
              center: true
            });
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              showClose: true,
              center: true
            });
          }
          this.DraftPop = false
          this.fileList = []
        })
        .catch(error=>{
            console.log(error);
        })
      },
      OnRemove (file, fileList) {
          this.fileList = fileList
      },
      onSubmit () {},
      onExceed(){
          this.$message({
              type: 'warning',
              message: '每次只能上传一个文件!',
              showClose: true,
              center: true
          });
      },
      selectChanged (value) {
        console.log(value);
      },
      httpRequest (options) {
          console.log(options);
      },
      // 草稿箱行内编辑
      toDetail(row) {
        this.changeCarrier(row.carrierArr)//根据船公司显示不通的集装箱信息
        this.VGMData = row
        this.importFormShow = false
        this.addOnlineShow = true
      },
      // 草稿箱行内删除
      draftDelete(row) {
        this.$confirm('删除后数据无法恢复, 确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.del(row.id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            showClose: true,
            center: true
          });
        });
      },
      // 删除
      del(val){
        var fd = new FormData()
          fd.append('id',val)
          axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
          axios.post( this.GLOBAL.BASE_URL1 +'/VGM_other/delete',fd)
          .then(res=>{
            if(res.data.status == '1'){
              this.$message({
                type: 'success',
                message: res.data.message,
                showClose: true,
                center: true
              });
              this.searchDraft()
            }else {
              this.$message({
                type: 'error',
                message: res.data.message,
                showClose: true,
                center: true
              });
            }
          })
          .catch(error=>{
            console.log(error);
          })
      },
      // 勾选
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 勾选发送VGM
      sendVGMExcel(){
        if(this.multipleSelection.length == 0){
          this.$message({
            type: 'warning',
            message: '请至少勾选一条数据进行操作!',
            showClose: true,
            center: true
          });
        }else{
          for(var i = 0; i<this.multipleSelection.length; i++){
            var item = this.multipleSelection[i]
            item.file_function = 2
            if(!item.port){
              return this.$message({
                type: 'warning',
                message: '第'+(i+1)+'个提单:港口不能为空',
                showClose: true,
                center: true
              });
            }else if(item.carrierArr.length == 0){
              return this.$message({
                type: 'warning',
                message: '第'+(i+1)+'个提单:船公司不能为空',
                showClose: true,
                center: true
              });
            }else if(!item.terminal){
              return this.$message({
                type: 'warning',
                message: '第'+(i+1)+'个提单:码头不能为空',
                showClose: true,
                center: true
              });
            }else if(!item.blno){
              return this.$message({
                type: 'warning',
                message: '第'+(i+1)+'个提单:提单号不能为空',
                showClose: true,
                center: true
              });
            }else if(!item.vessel){
              return this.$message({
                type: 'warning',
                message: '第'+(i+1)+'个提单:船名不能为空',
                showClose: true,
                center: true
              });
            }else if(!item.voyage){
              return this.$message({
                type: 'warning',
                message: '第'+(i+1)+'个提单:航次不能为空',
                showClose: true,
                center: true
              });
            }else if(item.container_detais.length == 0){
              return this.$message({
                type: 'warning',
                message: '第'+(i+1)+'个提单:集装箱信息不能为空',
                showClose: true,
                center: true
              });
            }else if(item.container_detais.length > 0){
              for (let j = 0; j < item.container_detais.length; j++) {
                var contItem = item.container_detais[j]
                var pattern = /^\w{4}\d{7}$/g;
                if(!contItem.ctn_no) {
                  return this.$message({
                    type: 'warning',
                    message: '第'+(i+1)+'个提单第'+ (j+1)+'集装箱:箱号不能为空',
                    showClose: true,
                    center: true
                  });
                }else if(!pattern.exec(contItem.ctn_no)){
                  return this.$message({
                    type: 'warning',
                    message: '第'+(i+1)+'个提单第'+ (j+1)+'集装箱:箱号格式不正确',
                    showClose: true,
                    center: true
                  });
                }else if(!contItem.ctn_type_size){
                  return this.$message({
                    type: 'warning',
                    message: '第'+(i+1)+'个提单第'+ (j+1)+'集装箱:箱型不能为空',
                    showClose: true,
                    center: true
                  });
                }else if(!contItem.vgm_gross_weight || contItem.vgm_gross_weight == "0" || contItem.vgm_gross_weight == 0){
                  return this.$message({
                    type: 'warning',
                    message: '第'+(i+1)+'个提单第'+ (j+1) +'集装箱:VGM重量不能为空',
                    showClose: true,
                    center: true
                  });
                }else if(!contItem.vgm_method){
                  return this.$message({
                    type: 'warning',
                    message: '第'+(i+1)+'个提单第'+ (j+1)+'集装箱:称重方式不能为空',
                    showClose: true,
                    center: true
                  });
                } else if((this.KMTCShow || this.RCLShow || this.SINOKORShow)&& !contItem.shipper_vgm_person_sig){
                  return this.$message({
                    type: 'warning',
                    message: '第'+(i+1)+'个提单第'+ (j+1)+'集装箱:签名不能为空',
                    showClose: true,
                    center: true
                  });
                }else if(this.IALShow && !contItem.vgm_date){
                  return this.$message({
                    type: 'warning',
                    message: '第'+(i+1)+'个提单第'+ (j+1)+'集装箱:称重时间不能为空',
                    showClose: true,
                    center: true
                  });
                }
              }
            }
          }
          axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
          axios.defaults.headers.common['Content-Type'] = 'application/json'
          axios.post(this.GLOBAL.BASE_URL1 + '/yitong_vgm/test',{
            data:this.multipleSelection
          })
          .then(res=>{
            if(res.data.status == 1){
              this.$message({
                type: 'success',
                message: res.data.message,
                showClose: true,
                center: true
              });
              this.searchDraft()
            }else if(res.data.status == 2){
              this.$message({
                type: 'error',
                message: res.data.message,
                showClose: true,
                center: true
              });
              this.searchDraft()
            }else{
              this.$message({
                type: 'error',
                message: res.data.message,
                showClose: true,
                center: true
              });
            }
          })
          .catch(error=>{
            console.log(error);
          })
        }
      },
      // 勾选删除
      selectDelete(){
        if(this.multipleSelection.length == 0){
          this.$message({
            type: 'warning',
            message: '请至少勾选一条数据进行操作!',
            showClose: true,
            center: true
          });
        }else {
          var idArr = []
          this.multipleSelection.forEach(ite=>{
            idArr.push(ite.id)
          })
          this.$confirm('删除后数据无法恢复, 确认删除?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            this.del(idArr.toString())
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              showClose: true,
              center: true
            });
          });
        }
      },
      // 查询草稿
      searchDraft(page,per_page){
        var data = {
          page:page || 1,
          per_page:per_page || 10,
          b_c_no:''
        }
        if(this.b_c_no) {
          data.b_c_no = this.b_c_no
        }
        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
        axios.get( this.GLOBAL.BASE_URL1 +'/VGM_other/check',{
          params:data
        })
        .then(res=>{
          if(res.data.status == 1){
            this.excelMsg = res.data.data
            this.count = res.data.total_record
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              showClose: true,
              center: true
            });
          }
        })
        .catch(error=>{
          console.log(error)
        })  
      },
      // 草稿分页
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.per_page = val
        this.searchDraft(this.page,this.per_page)
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val
        this.searchDraft(this.page,this.per_page)
      },
      // 在线新增保存草稿
      saveDraft(){
        console.log(this.VGMData);
        if(!this.VGMData.id){
          axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
          axios.defaults.headers.common['Content-Type'] = 'application/json'
          axios.post(this.GLOBAL.BASE_URL1 + '/VGM_other/save',{
            port:this.VGMData.port,
            carrier:this.carrier,
            file_function:2,
            reference_no:this.VGMData.blno,
            blno:this.VGMData.blno,
            receiver_code:this.VGMData.carrierArr[0],
            carrier_code:this.VGMData.carrierArr[1] || this.VGMData.carrierArr[0],
            vessel:this.VGMData.vessel,
            voyage:this.VGMData.voyage,
            terminal:this.VGMData.terminal,
            container_detais:this.VGMData.container_detais
          })
          .then(res=>{
            if(res.data.status == 1){
              this.$message({
                type: 'success',
                message: res.data.message,
                showClose: true,
                center: true
              });
              this.closeOnlineAdd() //关闭在线新增
            }else {
              this.$message({
                type: 'error',
                message: res.data.message,
                showClose: true,
                center: true
              });
            }
          })
          .catch(error=>{
            console.log(error);
          })
        }else{
          this.changeDraft()
        }
        
      },
      // 草稿修改重新保存草稿
      changeDraft(){
        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.post(this.GLOBAL.BASE_URL1 + '/VGM_other/change',{
          id:this.VGMData.id,
          port:this.VGMData.port,
          carrier:this.VGMData.carrier,
          file_function:2,
          reference_no:this.VGMData.blno,
          blno:this.VGMData.blno,
          receiver_code:this.VGMData.carrierArr[0],
          carrier_code:this.VGMData.carrierArr[1] || this.VGMData.carrierArr[0],
          vessel:this.VGMData.vessel,
          voyage:this.VGMData.voyage,
          terminal:this.VGMData.terminal,
          container_detais:this.VGMData.container_detais
        })
        .then(res=>{
          if(res.data.status == 1){
            this.$message({
              type: 'success',
              message: res.data.message,
              showClose: true,
              center: true
            });
            this.closeOnlineAdd() //关闭在线新增
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              showClose: true,
              center: true
            });
          }
        })
        .catch(error=>{
          console.log(error);
        })
      },
      // 已发送VGM
      sentSearch(page2,per_page2){
        var data = {
          page: page2 || 1,
          per_page: per_page2 || 10,
          b_c_no:''
        }
        if(this.sent_b_c_no){
          data.b_c_no = this.sent_b_c_no
        }
        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
        axios.get( this.GLOBAL.BASE_URL1 +'/VGM_other/send_check',{
          params:data
        })
        .then(res=>{
          console.log(res)
          if(res.data.status == 1){
            this.SentMsg = res.data.data
            this.count2 = res.data.total_record
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              showClose: true,
              center: true
            });
          }
        })
        .catch(error=>{
          console.log(error)
        })  
      },
      handleSizeChange2(val) {
        console.log(`每页 ${val} 条`);
        this.per_page2 = val
        this.sentSearch(this.page2,this.per_page2)
      },
      handleCurrentChange2(val) {
        console.log(`当前页: ${val}`);
        this.page2 = val
        this.sentSearch(this.page2,this.per_page2)
      },
    }
    
}