<template>
  <div class="vgmSend">
      <div class="asideVgm">
        <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            background-color="#fff"
            text-color="#F0801B"
            active-text-color="#F0801B">
            <el-menu-item index="1" @click="changeTab(1)">
                 <i class="el-icon-takeaway-box"></i>
                <span slot="title">VGM草稿箱</span>
            </el-menu-item>
            <el-menu-item index="2" @click="changeTab(2)">
                <i class="el-icon-position"></i>
                <span slot="title">已发送VGM</span>
            </el-menu-item>
        </el-menu>
      </div>
      <div class="vgmCont" v-show="tabShow == 1">
           <div class="draft" v-show="importFormShow">
                <div class="draft-head">
                    <el-row :gutter="20">
                        <el-col :span="6" :offset="6">
                            <div class="grid-content">
                                <button @click="importForm"><i class="el-icon-folder-add"></i> 表格导入</button>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content">
                                <button @click="addOnline()"><i class="el-icon-edit-outline"></i> 在线新增</button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
           </div>
           <!-- 表格导入 -->
            <div class="draft-cont">
                <div class="draft-import" v-show="importFormShow">
                    <div class="draft-import-head">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-button type="success" @click="sendVGMExcel()">发送VGM</el-button>
                                <el-button type="danger" @click="selectDelete()">删除</el-button>
                            </el-col>
                            <el-col :span="6" :offset="12">
                                <el-input placeholder="请输入提单号或箱号" v-model.trim="b_c_no" class="input-with-select">
                                    <el-button slot="append" type="warning" icon="el-icon-search" @click="searchDraft()"></el-button>
                                </el-input>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="draft-import-main">
                        <el-table
                            ref="multipleTable"
                            :border="true"
                            :data="excelMsg"
                            tooltip-effect="dark"
                            style="width: 100%"
                            @selection-change="handleSelectionChange">
                                <el-table-column
                                    type="selection"
                                    width="40">
                                </el-table-column>
                                <el-table-column
                                    prop="blno"
                                    label="提单号"
                                    width="150">
                                </el-table-column>
                                <el-table-column
                                    label="港口"
                                    width="100"
                                    >
                                     <template slot-scope="scope">
                                        <span>{{scope.row.port | portFilter}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="vessel"
                                    label="船名"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="voyage"
                                    label="航次"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="terminal"
                                    label="码头"
                                    >
                                    <template slot-scope="scope">
                                        <span>{{scope.row.terminal | matouFilter}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="carrier"
                                    label="船公司"
                                    width="100"
                                    >
                                </el-table-column>
                                <el-table-column
                                    label="校验状态"
                                    width="100"
                                    >
                                     <template slot-scope="scope">
                                        <el-tooltip v-show="scope.row.inspection_status == 1"  class="item" effect="dark" :content="scope.row.inspection_remark" placement="bottom">
                                            <img src="../../assets/pass.png" alt="">
                                        </el-tooltip>
                                         <el-tooltip v-show="scope.row.inspection_status == 0"  class="item" effect="dark" :content="scope.row.inspection_remark" placement="bottom">
                                            <img src="../../assets/noPass.png" alt="">
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="箱号"
                                    width="125"
                                    >
                                    <template slot-scope="scope">
                                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                                            {{item.ctn_no}}
                                        </p>
                                    </template>
                                </el-table-column>
                                 <el-table-column
                                    label="箱型"
                                    width="80"
                                    >
                                    <template slot-scope="scope">
                                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                                            {{item.ctn_type_size}}
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="VGM重量(KGS)"
                                    width="130">
                                     <template slot-scope="scope">
                                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                                            {{item.vgm_gross_weight}}
                                        </p>
                                    </template>
                                </el-table-column>
                                 <el-table-column
                                    label="称重方式"
                                    width="80"
                                    >
                                    <template slot-scope="scope">
                                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                                            {{item.vgm_method}}
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="shipper_vgm_person_sig"
                                    label="签名"
                                    width="100"
                                    >
                                     <template slot-scope="scope">
                                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                                            {{item.shipper_vgm_person_sig}}
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="update_time"
                                    label="更新时间"
                                    width="165"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop=""
                                    label="操作"
                                    width="125"
                                    >
                                     <template slot-scope="scope">
                                        <el-button
                                            size="mini"
                                            type="primary"
                                            @click="toDetail(scope.row)">编辑</el-button>
                                        <el-button
                                            size="mini"
                                            type="danger"
                                            @click="draftDelete(scope.row)">删除</el-button>
                                    </template>
                                </el-table-column>
                                <div slot="empty" class="emptyBg">
                                    <img src="../../assets/empty.png" alt="">
                                    <p>没有记录哦~</p>
                                </div>
                        </el-table>
                        <div class="vgm-page">
                            <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="[10, 20, 30, 40, 50, 100]"
                                :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="count">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 在线新增 -->
            <div class="draft-add" v-show="addOnlineShow">
                <div class="draft-add-tit">
                    <el-row>
                        <el-button type="warning" @click="addBox()">添加集装箱</el-button>
                        <el-button type="success" @click="sendVGM()">保存并发送VGM</el-button>
                        <el-button type="success" @click="saveDraft()" plain>保存草稿</el-button>
                        <el-button type="primary" @click="autoComplete = true">自动填充数据</el-button>
                        <el-button type="info" plain @click="closeOnlineAdd()">关闭</el-button>
                    </el-row>
                </div>
                <div class="draft-add-main">
                    <div class="draft-add-fif">
                        <el-form ref="VGMData" :rules="rules" label-width="100px" :model="VGMData">
                            <el-row :gutter="20">
                                <div class="draft-add-item">
                                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                                        <el-form-item label="港口" prop="port">
                                            <el-select v-model="VGMData.port" placeholder="请选择">
                                                <el-option label="上海" value="SHANGHAI"></el-option>
                                                <el-option label="深圳" value="SHENZHEN"></el-option>
                                                <el-option label="广州" value="GAUNGZHOU"></el-option>
                                                <el-option label="香港" value="HONGKONG"></el-option>
                                                <el-option label="珠海" value="ZHUHAI"></el-option>
                                                <el-option label="大连" value="DALIAN"></el-option>
                                                <el-option label="宁波" value="NINGBO"></el-option>
                                                <el-option label="青岛" value="QINGDAO"></el-option>
                                                <el-option label="天津" value="TIANJIN"></el-option>
                                                <el-option label="厦门" value="XIAMEN"></el-option>
                                                <el-option label="佛山" value="FOSHAN"></el-option>
                                                <el-option label="顺德" value="SHUNDE"></el-option>
                                                <el-option label="中山" value="ZHONGSHAN"></el-option>
                                                <el-option label="江门" value="JIANGMEN"></el-option>
                                                <el-option label="肇庆" value="ZHAOQING"></el-option>
                                                <el-option label="东莞" value="DONGGUAN"></el-option>
                                                <el-option label="HAI PHONG" value="HAIPONG"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </div>
                                 <div class="draft-add-item">
                                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                                        <el-form-item label="船公司" prop="carrierArr">
                                            <el-cascader
                                            v-model="VGMData.carrierArr"
                                            :options="carrierList"
                                            @change="changeCarrier">
                                            </el-cascader>
                                        </el-form-item>
                                    </el-col>
                                </div>
                               <div class="draft-add-item">
                                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                                        <el-form-item label="码头" prop="terminal">
                                            <el-select v-model="VGMData.terminal" placeholder="请选择">
                                            <el-option v-for="item in matouList"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col> 
                                </div>
                            </el-row>
                            <el-row :gutter="20">
                                <div class="draft-add-item">
                                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                                        <el-form-item label="提单号" prop="blno">
                                            <el-input placeholder="请输入提单号" v-model="VGMData.blno" @input="VGMData.blno = VGMData.blno.replace(/[^0-9a-zA-Z]/g,'').toUpperCase()" clearable></el-input>
                                        </el-form-item>
                                    </el-col>
                                </div>
                                <div class="draft-add-item">
                                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                                        <el-form-item label="船名" prop="vessel">
                                            <el-input placeholder="请输入船名" v-model="VGMData.vessel" @input="VGMData.vessel = VGMData.vessel.replace(/[^0-9a-zA-Z\s]/g,'').toUpperCase()" clearable></el-input>
                                        </el-form-item>
                                    </el-col>
                                </div>
                                <div class="draft-add-item">
                                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                                        <el-form-item label="航次" prop="voyage">
                                            <el-input placeholder="请输入航次" v-model="VGMData.voyage" @input="VGMData.voyage = VGMData.voyage.replace(/[^0-9a-zA-Z-]/g,'').toUpperCase()" clearable></el-input>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-row>
                        </el-form>
                    </div>
                    <div class="draft-add-mag">
                         <el-table
                            ref="multipleTable"
                            :data="VGMData.container_detais"
                            :border="true"
                            tooltip-effect="dark"
                            style="width: 100%">
                                <el-table-column
                                    label="箱号"
                                    prop="ctn_no"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="seal_no"
                                    label="封号"
                                    v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)">
                                </el-table-column>
                                <el-table-column
                                    prop="ctn_type_size"
                                    label="箱型"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="vgm_gross_weight"
                                    label="VGM重量(KGS)">
                                </el-table-column>
                                <el-table-column
                                    prop="vgm_method"
                                    label="称重方式"
                                    >
                                </el-table-column>
                                 <el-table-column
                                    prop="shipper_vgm_person_sig"
                                    label="签名"
                                    v-if="(RCLShow || SINOKORShow || KMTCShow) || !(KMTCShow || TSLShow || RCLShow || SINOKORShow)"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="company_name"
                                    label="称重方"
                                    v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="cargo_tare_weight"
                                    label="皮重"
                                    v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="vgm_date"
                                    label="称重时间"
                                    v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop=""
                                    label="操作"
                                    >
                                    <template slot-scope="scope">
                                         <el-button
                                            size="mini"
                                            type="primary"
                                            @click="auitBoxItem(scope.$index, scope.row)"
                                            >编辑</el-button>
                                        <el-button
                                            size="mini"
                                            type="danger"
                                            @click.native.prevent="delBoxItem(scope.$index, VGMData.container_detais)"
                                            >删除</el-button>
                                    </template>
                                </el-table-column>
                                <div slot="empty" class="emptyBg">
                                    <img src="../../assets/empty.png" alt="">
                                    <p>没有记录哦~</p>
                                </div>
                        </el-table>
                    </div>
                </div>
            </div>
      </div>
      <!-- 已发送 -->
      <div class="vgmSent" v-show="tabShow == 2">
        <div class="vgmSent-tit">
             <el-row :gutter="20">
                <el-col :span="6" :offset="18">
                    <el-input placeholder="请输入提单号或箱号" v-model.trim="sent_b_c_no" class="input-with-select">
                        <el-button slot="append" type="warning" icon="el-icon-search" @click="sentSearch()"></el-button>
                    </el-input>
                </el-col>
             </el-row>
        </div>
        <div class="vgmSent-cont">
            <el-table
                ref="multipleTable"
                :border="true"
                :data="SentMsg"
                tooltip-effect="dark"
                style="width: 100%">
                <el-table-column
                    prop="blno"
                    label="提单号"
                    width="150">
                </el-table-column>
                <el-table-column
                    label="港口"
                    width="100"
                    >
                        <template slot-scope="scope">
                        <span>{{scope.row.port | portFilter}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="vessel"
                    label="船名"
                    >
                </el-table-column>
                <el-table-column
                    prop="voyage"
                    label="航次"
                    >
                </el-table-column>
                <el-table-column
                    prop="terminal"
                    label="码头"
                    >
                    <template slot-scope="scope">
                        <span>{{scope.row.terminal | matouFilter}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="carrier"
                    label="船公司"
                    width="100"
                    >
                </el-table-column>
                <el-table-column
                    label="箱号"
                    width="125"
                    >
                    <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.ctn_no}}
                        </p>
                    </template>
                </el-table-column>
                    <el-table-column
                    label="箱型"
                    width="80"
                    >
                    <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.ctn_type_size}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="VGM重量(KGS)"
                    width="130">
                        <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.vgm_gross_weight}}
                        </p>
                    </template>
                </el-table-column>
                    <el-table-column
                    label="称重方式"
                    width="80"
                    >
                    <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.vgm_method}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="shipper_vgm_person_sig"
                    label="签名"
                    width="100"
                    >
                        <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.shipper_vgm_person_sig}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="更新时间"
                    width="165"
                    >
                </el-table-column>
                <div slot="empty" class="emptyBg">
                    <img src="../../assets/empty.png" alt="">
                    <p>没有记录哦~</p>
                </div>
            </el-table>
            <div class="vgm-page">
                <el-pagination
                    background
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                    :current-page="currentPage2"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count2">
                </el-pagination>
            </div>
        </div>
      </div>
      <!-- 表格上传 -->
      <div class="draft-import-pop" v-show="DraftPop">
        <div class="draft-import-pop-main">
            <div class="draft-pop-tit">
                <h2>VGM表格导入/下载<i class="el-icon-close" @click="closeDraftPop"></i></h2>
                <p>-----①选择船公司(必选)②点击下载模板，按照格式填写-----</p>
            </div>
            <div class="draft-pop-cont">
                <div class="draft-pop-carrier">
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <label for="">船公司<span>*</span></label>
                        </el-col>
                        <el-col :span="19">
                            <el-cascader
                                v-model="carrierBG"
                                :options="carrierList"
                                >
                            </el-cascader>
                        </el-col>
                    </el-row>
                </div>
                <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :auto-upload="false"
                    :on-change="OnSuccess"
                    :on-remove="OnRemove"
                    :on-exceed="onExceed"
                    :http-request="httpRequest"
                    :file-list="fileList"
                    accept=".xlsx,.xls"
                    :limit="1"
                    name="file">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </div>
            <div class="draft-pop-btn">
                <el-button type="info" plain @click="closeDraftPop()">取消</el-button>
                 <!-- <el-button type="warning" @click="onSubmit()">上传</el-button> -->
                <el-button type="success" @click="downloadModel()">下载模板</el-button>
            </div>
        </div> 
      </div>
      <!-- 添加集装箱 -->
      <div class="addbox-pop" v-show="addboxShow">
          <div class="addbox-pop-main">
              <div class="addbox-pop-tit">
                  <h2>集装箱<i class="el-icon-close" @click="closeAddBox()"></i></h2>
              </div>
              <div class="addbox-pop-cont">
                  <el-form ref="formLabelAlign" :rules="rules" label-width="100px" :model="formLabelAlign">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="addbox-item">
                                <el-form-item label="箱号" prop="ctn_no">
                                    <el-input v-model="formLabelAlign.ctn_no" @input="formLabelAlign.ctn_no = formLabelAlign.ctn_no.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="addbox-item" v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)">
                                <el-form-item label="封号">
                                    <el-input v-model="formLabelAlign.seal_no" @input="formLabelAlign.seal_no = formLabelAlign.seal_no.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="addbox-item">
                                 <el-form-item label="箱型" prop="ctn_type_size">
                                   <el-select v-model="formLabelAlign.ctn_type_size" placeholder="请选择箱型">
                                        <el-option  v-for="item in typeList"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-col>
                       <el-col :span="8">
                            <div class="addbox-item">
                                <el-form-item label="VGM重量" prop="vgm_gross_weight">
                                    <el-input v-model="formLabelAlign.vgm_gross_weight" @input="checkWeight(formLabelAlign.vgm_gross_weight)" placeholder="单位为：KGS"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="addbox-item">
                                <el-form-item label="称重方式" prop="vgm_method">
                                     <el-select v-model="formLabelAlign.vgm_method" placeholder="请选择">
                                         <el-option label="SM1(整体称重法)" value="SM1"></el-option>
                                         <el-option label="SM2(累加计算法)" value="SM2"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-col>
                         <el-col :span="8">
                            <div class="addbox-item" v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)">
                                 <el-form-item label="称重方">
                                    <el-input v-model="formLabelAlign.company_name"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="addbox-item" v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)">
                                <el-form-item label="签名">
                                    <el-input v-model="formLabelAlign.shipper_vgm_person_sig"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="addbox-item" v-if="RCLShow || SINOKORShow || KMTCShow">
                                <el-form-item label="签名" prop="shipper_vgm_person_sig">
                                    <el-input v-model="formLabelAlign.shipper_vgm_person_sig" ></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="addbox-item" v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow)">
                                <el-form-item label="皮重">
                                    <el-input v-model="formLabelAlign.cargo_tare_weight" @input="checkTareWeight(formLabelAlign.cargo_tare_weight)"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                         <el-col :span="8">
                            <div class="addbox-item" v-if="!(KMTCShow || TSLShow || RCLShow || SINOKORShow || IALShow)">
                                <el-form-item label="称重日期">
                                    <el-date-picker
                                        v-model="formLabelAlign.vgm_date"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="addbox-item" v-if="IALShow">
                                <el-form-item label="称重日期" prop="vgm_date">
                                    <el-date-picker
                                        v-model="formLabelAlign.vgm_date"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                  </el-form>
              </div>
              <div class="addbox-btn">
                  <el-button type="success" v-show="saveBtn" @click="saveBoxSub()">保存</el-button>
                  <el-button type="success" v-show="!saveBtn" @click="AddBoxSub()">添加</el-button>
              </div>
          </div>
      </div>
       <!-- 自动填充数据 -->
        <el-dialog
            :visible.sync="autoComplete"
            title="自动填充数据"
            width="30%"
            v-if="autoComplete"
            >
            <el-form :model="autoCompleteMsg" :rules="autoCompleteRules" ref="autoCompleteMsg" label-width="100px">
                <el-form-item label="提单号" prop="bl_no">
                    <el-input v-model="autoCompleteMsg.bl_no"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="autoComplete = false">取 消</el-button>
                <el-button type="primary" @click="autoCompleteSeach">确 定</el-button>
            </span>
        </el-dialog>
  </div>
</template>

<script>
    import VgmSend from './VgmSend.js';
    export default VgmSend;
</script>

<style scoped>
    @import './VgmSend.css';
</style>